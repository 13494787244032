window.App = {};
window.global = window;
try {
	Bugsnag.start({
				apiKey: CONFIG.HUBHEAD_PUBLIC_BUGSNAG_API_KEY,
				appVersion: CONFIG.HUBHEAD_RELEASE || CONFIG.HUBHEAD_VERSION,
				releaseStage: CONFIG.HUBHEAD_STAGE || 'production',
				onError: (event) => { console.error(event); }
	});
	App.Bugsnag = Bugsnag;
} catch (e) {
	console.error(e)
}

(window.location.protocol === "https:" || window.location.host === "localhost") && 'serviceWorker' in navigator && navigator.serviceWorker.getRegistration && navigator.serviceWorker.getRegistration().then(reg => {
	return reg && reg.unregister()
});

const MOBILE_REGEXP = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
var blocks = [];
var scrollTop = window.scrollY;
var direction;
var currentSlide;
const scrollSpeed = 0.7;
var viewportHeight, viewportWidth, fadeInZone, fadeOutZone, shortBlockPaddingBottom;

var throttlePause;
const throttle = (func, time = 50) => {

  if (throttlePause) return;
  throttlePause = true;
 
  setTimeout(() => {
    func();
    throttlePause = false;
  }, time);
};

function debounce(func, timeout = 300){
  let timer = null;
  return (...args) => {
    if (timer) {
	 	clearTimeout(timer);
		timer = null;   	
    }
    timer = setTimeout(() => {
    	func.apply(this, args);
    	timer = null;
    }, timeout);
  };
}

function initAgent() {
	document.body.parentElement.removeAttribute('mkb-device');
	document.body.parentElement.removeAttribute('touch-device');
	// Мобильная версия - если агент мобильный, или ширина экрана меньше 600
	window.mobileAgent = MOBILE_REGEXP.test(navigator.userAgent) || navigator.userAgent === 'capacitor' || window.innerWidth < 600;
	document.body.parentElement.setAttribute(!mobileAgent ? 'mkb-device' : 'touch-device', 'true');
}

var slideOnSpinner; // Слайд, с которого начали скроллить
var slideOnSpinnerY; // Позиция слайда на момент начала скролла

// Вызов спиннера по дебаунсу
const showSpinner = debounce(() => {
	// Показываем спиннер
	if (slideOnSpinner == currentSlide // если слайд не сменился
		&& slideOnSpinnerY == slideOnSpinner.view.style.transform // если слайд не смещался
		&& (slideOnSpinnerY == 'translateY(0px)' || slideOnSpinnerY == '') // если слайд виден (а не в статичном состоянии за границами окна)
	) {
		requestAnimationFrame(() =>	document.querySelector('.spinner:not(.viewed)')?.classList.add('viewed'));
		setTimeout(() => {
			// По завершении спиннер обнуляем
			document.querySelector('.spinner.viewed')?.classList.remove('viewed');
		}, 2000);
	}
	// Обнуляем по завершению скролла
	slideOnSpinner = null;
	slideOnSpinnerY = null;
});

// Показываем индикатор
const runSpinner = () => {
	// Когда скроллим вниз
	if (direction) {
		// Записываем текущий слайд и его позицию, если это начало скролла
		if (!slideOnSpinner) {
			slideOnSpinner = currentSlide;
			slideOnSpinnerY = currentSlide.view.style.transform;
		}
		showSpinner();
	}
};


// Вычисление зон фэйда относительно размеров окна браузера
function setFadeZones() {
	viewportHeight = window.innerHeight;
	viewportWidth = window.innerWidth;

	fadeInZone = viewportHeight*0.6;
	fadeOutZone = viewportHeight*0.4;

	let lastBlockHeight = 438; //высота последнего блока

	shortBlockPaddingBottom = Math.max((viewportHeight-lastBlockHeight)/2, 250);
}

setFadeZones();

// Инициализация сообщения об использовании кукис
function initCookies() {
	const alert = document.querySelector('.cookie-alert');
	if (!document.cookie?.includes('closedCookiePopup=true')) {
		alert.classList.add('visible');
		document.querySelector(".cookie-accept").onclick = function() {
	        alert.classList.add("hide");
	        alert.addEventListener("transitionend", function() {
	            alert.remove();
				document.cookie = `closedCookiePopup=true`;
			});
		}
	} else {
		alert.remove();
	}
}


// Класс слайда с медиа-содержимым
// При скролле показывает медиа в стиле галереи
function Slide(domElement, block) {
	// dom-элемент слайда в вью блока
	this.el = domElement;
	// Ссылка на объект родительского блока
	this.block = block;
	// dom-элемент медиа-содержимого
	this.inner = domElement.children[0];
	this.video = this.inner.querySelector('video');
	
	this.initDimensions()
		
	// Формируем dom вью слайда
	const slideView = document.createElement('div');
	slideView.classList.add('media-slide-view');
	// Вставляем во вью содержимое слайда в dom-блока
	slideView.innerHTML = this.el.innerHTML;
	// Ссылка из объекта слайда на его вью (видимое отображение)
	this.view = slideView;
	this.viewImg = slideView.querySelector('img');
	this.viewVideo = slideView.querySelector('video');
	document.body.appendChild(slideView);

	if (this.viewVideo) {
		this.play();
		this.video.remove();
	} 
}

Slide.prototype = {
	initDimensions() {
		const elBounding = this.el.getBoundingClientRect();
		const innerBounding = this.inner.getBoundingClientRect();
		this.offsetTop = elBounding.top - this.block.offsetTop;
		this.offsetInnerTop = innerBounding.top - this.block.offsetTop;
		this.offsetHeight = elBounding.height;
		this.offsetInnerHeight = innerBounding.height;
	},
	// Отвечает, нужна ли анимация при показе/скрытии вью слайда
	animationAccepted() {
		// Анимируем появление/закрытие слайда только если это смена одного слайда на другой
		const top = this.block.y + this.offsetTop;
		const height = this.offsetHeight;

		return top < viewportHeight*0.75 // верхняя граница слайда выше 3/4 экрана
			&& top+height > 0.25*viewportHeight; // нижняя граница слайда ниже 1/4 экрана
	},
	// Отвечает, виден ли на экране слайд (нужно ли показать view)
	acceptedToView() {
		const top = this.block.y + this.offsetTop;
		const height = this.offsetHeight;
		// const { top, height } = this.el.getBoundingClientRect();

		// Первый слайд
		if (this.isFirst()) {
			return top < viewportHeight // верхняя граница слайда выше нижней границы экрана
				&& top+height > 0.5*viewportHeight; // нижняя граница слайда ниже середины экрана
		}
		// Последний слайд
		if (this.isLast()) {
			return top < viewportHeight*0.5 // верхняя граница слайда выше середины экрана
				&& top > -1*height; // нижняя граница слайда ниже верхней границы экрана
		}

		// Слайды между
		return top < viewportHeight*0.5 // верхняя граница слайда выше середины экрана
			&& top+height > 0.5*viewportHeight; // нижняя граница слайда ниже середины экрана
	},
	// Отвечает является ли слайд первым в блоке
	isFirst() {
		return this.block.slides[0] === this;
	},
	// Отвечает является ли слайд последним в блоке
	isLast() {
		return this.block.slides.slice(-1)[0] === this;
	},
	detach() {
		this.view.classList.remove('stage');
	},
	attach() {
		this.view.classList.add('stage');
	},
	// Показывает вью слайда
	showView() {
		// Если слайд уже показан, то выходим
		if (this.viewed) return;
		currentSlide = this;
		// Если в этом блоке уже открыт другой слайд, то закрываем его
		this.block.slide?.hideView();

		// Ссылка из объекта родительского блока на текущий показаный слайд
		this.block.slide = this;
		this.viewed = true;
		this.view.classList.remove('hiding', 'no-animation', 'next', 'prev');
		this.view.classList.add('show', direction ? 'next' : 'prev');

		this.play();
	},
	// Закрывает вью слайда
	hideView() {
		// Если слайд уже закрыт, то выходим
		if (!this.viewed) return;
		
		// Очищаем классы, установленные при появлении вью слайда
		this.view.classList.remove('show', 'no-animation', 'next', 'prev');
		// Указываем классы направления анимации и скрытия вью слайда
		this.view.classList.add('hiding', direction ? 'next' : 'prev');

		if (!this.animationAccepted()) {
			// Если анимация не нужна, сразу удаляем вью слайда
			this.view.classList.add('no-animation');
		}
		
		this.viewed = false;
		// Удаляем ссылку на текущий слайд из объекта родительского блока
		delete this.block.slide;
		
		this.pause();
	},
	play() {
		if (this.viewed && this.viewVideo && this.viewVideo.paused) {
			this.viewVideo.play().catch(function(error) {
				App.Bugsnag?.notify(error, event => {
					event.severity = 'warning';
				});
			}.bind(this))
		}
	},
	pause() {
		if (this.viewVideo && !this.viewVideo.paused) {
			this.viewVideo.pause()
		}
	}
}


// Класс блока на странице
// Block основывается на dom-элементе и при скролле показывает содержимое с кастомной анимацией
function Block(domElement) {
	// dom-элемент блока на странице
	this.el = domElement;
	// dom-элемент содержимого блока на странице
	this.inner = this.el.children[0];

	// Выставляем нужную высоту блока (влияет на время для проскролла блока)
	this.initHeight();

	const view = document.createElement('div');
	view.classList.add('block-view');
	// Вставляем во вью содержимое блока
	view.innerHTML = this.el.innerHTML;
	// Ссылка на элемент вью
	this.view = view;
	// Ссылка на внутренний элемент вью для перемещения
	this.viewInner = view.children[0];
	// Ссылка на контент вью для ресайза
	this.viewInnerContent = this.viewInner.children[0];

	// Render
	document.body.appendChild(view);

	// При отрисовке вью, инициализируем слайды из содержимого
	this.initSlides();
}

Block.prototype = {
	// Инициализирует высоту блока
	initHeight() {
		const contentHeight = this.inner.getBoundingClientRect().height;
		let blockHeight;

		if (this.isShort()) {
			// Если блок последний — его нужно зафэйдить и проскроллить исключительно на его высоту
			// и на фиксированный отступ снизу экрана для создания места под футер
			blockHeight = contentHeight + shortBlockPaddingBottom;
		} else {
			// Если блок не последний
			blockHeight = fadeInZone + (contentHeight+viewportHeight-fadeInZone-fadeOutZone)/scrollSpeed + fadeOutZone;
		}

		// Высота вычисляется с учетом настроек анимации
		this.el.style.height = `${blockHeight}px`;

		const { top } = this.inner.getBoundingClientRect();
		this.offsetTop = top;
		this.offsetHeight = blockHeight;
		this.scrollOffset = top + window.scrollY; 

		if (this.slides) this.slides.forEach(slide => slide.initDimensions())
	},
	// Инициализирует слайды показанного блока
	initSlides() {
		// Ищем в содержимом медиа для слайдов
		const slideElements = this.el.querySelectorAll('.media-slide');
		if (slideElements.length) {
			// Если нашли подходящие медиа, то на основе них создаём слайды
			this.slides = new Array(...slideElements).map(el => new Slide(el, this));
		}

		this.view.querySelectorAll('video').forEach(el => el.remove())
	},
	// Показывает вью (видимый элемент) блока
	showView() {
		// Если блок уже показан, то выходим
		if (this.viewed) return;
		this.view.classList.add('show');
		this.viewed = true;

		if (this.slides) this.slides.forEach(slide => slide.attach());
	},
	// Закрывает вью блока
	hideView() {
		// Если блок не показан, то выходим
		if (!this.viewed) return;

		this.viewed = false;
		this.view.classList.remove('show');

		// Закрываем слайды, при закрытии вью
		if (this.slides) this.slides.forEach(slide => slide.detach());
	},
	// Блок сокращен по высоте
	isShort() {
		return this.el.classList.contains('block-wrapper__short');
	},
	// Определяет необходимость показа/скрытия слайдов
	detectSlide() {
		// Если слайдов в блоке нет, то сразу выходим
		if (!this.slides) return;
		
		// Перебираем слайды блока
		for (const slide of this.slides) {
			// Проверяем, нужно ли показать слайд
			if (slide.acceptedToView()) {
				// const updateSpinner = slide.viewed;
				// Вызываем показ слайда
				slide.showView();
				
				const top = this.y + slide.offsetInnerTop;
				const height = slide.offsetInnerHeight;

				// Первый слайд смещается вместе с вью блока от низа до середины экрана
				if (slide.isFirst() && slide.viewed) {
					const y = Math.max(top-(viewportHeight-height)/2, 0);
					slide.view.style.transform = `translateY(${y}px)`;

					// Если инициализировались и слайд в зоне анимации, но всё равно смещен, то показываем без анимации
					if (y > 0) {
						slide.view.classList.add("no-animation");
					}
				}

				// Последний слайд смещается вместе с вью блока от середины экрана до верха
				else if (slide.isLast() && slide.viewed) {
					const y = Math.min(top-(viewportHeight-height)/2, 0);
					slide.view.style.transform = `translateY(${y}px)`;
					
					slide.view.style.opacity = (1-this.fadeOutRatio);
					
					// Если инициализировались и слайд в зоне анимации, но всё равно смещен, то показываем без анимации
					if (y < 0) {
						slide.view.classList.add("no-animation");
					}
				}

				throttle(runSpinner);

				// Если найден слайд для показа, то выходим из цикла (показываем первый подходящий слайд)
				return;
			}

			// Если слайд не подходит для показа и в данный момент он открыт
			if (slide === this.slide) {
				// Скрываем слайд
				slide.hideView();
			}
		}
	}
}

// Вычисляет блоки, которые нужно показать/скрыть и вычилсяет параметры для анимации скролла
function detectBlock() {
	for (const block of blocks) {
		const top = block.scrollOffset - scrollTop;
		const height = block.offsetHeight;

		// Если блок выходит за границы видимости
		if (top > viewportHeight || top+height < 0) {
			// Скрываем блок
			block.hideView();
			// Переходим к следующему
			continue;
		}
		
		// Показываем блок
		block.showView();

		// Положение экрана (px), от верхней границы блока
		const viewedHeight = viewportHeight - top;
		// Высота зоны равномерного скролла, зависит от высоты контента (для блока, стоящего не последним)
		const staticHeight = block.isShort() ? height : height-fadeInZone-fadeOutZone;
		// Если блок короткий — вся его высота будет фэйдинится
		const _fadeInZone = block.isShort() ? staticHeight : fadeInZone;

		// Сколько (px) проскроллили в зонах фэйд ина, равномерного скролла и фэйд-аута
		const fadeIn = viewedHeight < 0 ? 0 : Math.min(viewedHeight, _fadeInZone); // Для блока в конце вся высота является зоной fadeIn
		const staticZone = fadeIn < fadeInZone ? 0 : Math.min(viewedHeight-fadeIn, staticHeight);
		const fadeOut = (staticZone < staticHeight) || block.isShort() ? 0 : Math.min(viewedHeight-fadeIn-staticZone, fadeOutZone);

		// То же самое, но относительно высоты зон (0...1)
		const fadeInRatio = fadeIn/_fadeInZone;
		const staticZoneRatio = staticZone/staticHeight;
		const fadeOutRatio = block.fadeOutRatio = fadeOut/fadeOutZone;
		
		// Функции плавного изменения значения (0...1)
		const easing = (x) => (1 - Math.pow(1 - x, 3));
		const boosting = (x) => x*x*x;

		// Вычисляем смещение с учетом каждой зоны

		// Для фэйдов складываем абсолютное смещение, замедленное в два раза,
		// и смещение с учетом плавного замедления/ускорения.
		const fadeInOffset = fadeIn*scrollSpeed + _fadeInZone*(1-scrollSpeed)*easing(fadeInRatio);
		const staticZoneOffset = staticHeight*scrollSpeed*staticZoneRatio;
		const fadeOutOffset = fadeOut*scrollSpeed + fadeOutZone*(1-scrollSpeed)*boosting(fadeOutRatio);

		// Итоговое смещение состоит из оффсета, чтобы изначально убрать контент за нижнюю границу экрана,
		// и уменьшения этого оффсета по мере скролла
		const y = viewportHeight - fadeInOffset - staticZoneOffset - fadeOutOffset;


		block.viewInner.style.opacity = fadeOutRatio ? boosting(1-fadeOutRatio) : easing(fadeInRatio);
		block.viewInner.style.transform = `translateY(${y}px)`;
		block.viewInnerContent.style.transform = `scale(${0.8+0.2*easing(fadeInRatio)})`;

		block.y = y;

		// У видимого блока определяем необходимость показать/скрыть слайд галереи
		requestAnimationFrame(() => block.detectSlide());
	}
}




let menuWrapper;
function closeMenu() {
	menuWrapper.classList.remove('showed');
	menuWrapper.style.height = 0;
}
function initMenu() {
	// Меню уже инициализировано
	if (menuWrapper) return;

	menuWrapper = document.querySelector('.auth-bar-menu-wrapper');
	const menu = document.querySelector('.auth-bar-menu');
	const menuOpen = document.querySelector('.auth-bar-menu-open');
	menuOpen?.addEventListener('click', () => {
		if (menuWrapper.classList.contains('showed')) {
			closeMenu()
		} else {
			menuWrapper.classList.add('showed');
			// Высота меню - высота содержимого
			menuWrapper.style.height = menu.clientHeight + 'px';
		}
	})
	window.addEventListener('scroll', () => {
		closeMenu();
	});
}

function initBlockAnimation() {
	if (blocks.length) return;
	// Находим блоки на странице и создаём для них объекты управления
	blocks = new Array(...document.querySelectorAll('.block-wrapper')).map(el => new Block(el));

	// Скролл окна
	window.addEventListener('scroll', () => {
		// Сохраняем направление скролла (для слайдов)
		direction = scrollTop < window.scrollY;
		// Обновляем текущее положение скролла
		scrollTop = window.scrollY;
		detectBlock();
	});

	document.fonts?.ready.then(function () {
		detectBlock();
	});

	// Ресайз окна
	window.addEventListener('resize', () => {
		// Переопределяем новые значения границ анимации блоков
		setFadeZones();

		// Обновляем высоту всех блоков
		blocks.forEach(block => block.initHeight());
		detectBlock();
	})
}


function initApp() {
	initAgent();

	// Если десктопная версия, то инициализируем анимации
	if (!mobileAgent) initBlockAnimation();
	else {
		// Если мобильная версия
		for (const block of blocks) {
			// Сбрасываем кастомную высоту блоков
			block.el.style.height = `auto`;
			// прячем все показанные блоки
			block.hideView();
		}
		// Удаляем блоки
		blocks = [];
		// Инициализируем мобильное меню
		initMenu();
	}
}
function initMxEvents() {
	mp.track(`Page load`);

	document.querySelector('[role="menu_loginLink"]')?.addEventListener('click', () => {
		mp.track(`login-menu`);
	})
	document.querySelector('[role="menu_signupLink"]')?.addEventListener('click', () => {
		mp.track(`signup-menu`);
	})
	document.querySelector('[role="header_signupLink"]')?.addEventListener('click', () => {
		mp.track(`signup-main-block`);
	})
	document.querySelector('.block-view [role="footer_signupLink"]')?.addEventListener('click', () => {
		mp.track(`signup-bottom-block`);
	});
}
// При завершении загрузки инициализируем объекты на странице
window.addEventListener('DOMContentLoaded', () => {
	// Проставляем агента
	initAgent();
	// Инициализируем cookies-popup
	initCookies();
	initApp();
	initMxEvents();

	if (mobileAgent) {
		document.querySelectorAll('video').forEach(e => {
			try {
				e.setAttribute('src', e.getAttribute('src').replace('/img', '/img/s'));
			}
			catch (e) {
				console.error(e);
				App.Bugsnag?.notify(e);
			}
		});
	}
	// c135588: Принудительно стартуем видео
	document.querySelectorAll('.media-block video').forEach(e => {
		try {	e.play() }
		catch (error) {
			App.Bugsnag?.notify(error, event => {
				event.severity = 'warning';
			});
		}
	});
});
if (document.fonts) document.fonts.onloadingdone = () => {
	for (const block of blocks) block.initHeight();
}
window.addEventListener('resize', () => {
	initApp();
})
